import api from '@/api';
import utils from '@/utils/algorand';

const liquidity = {
  namespaced: true,
  state: {
    rewardsPerToken: 1,
    rewardsPerToken2: 1,
    isLoading: false,
    lastDistribution: undefined,
    snapShot: undefined,
    history: undefined,
    decimals: 0,
    assetId: undefined,
    assetDecimals: 0,
  },
  actions: {
    resetState(context) {
      context.commit('SET_IS_LOADING', false);
    },
    async getLastDistribution(context) {
      const response = await api.spindle.get('/api/public/distribution-timestamp');
      if (response.success) {
        context.commit('SET_LAST_DISTRIBUTION', response.data);
      } else {
        context.commit('SET_LAST_DISTRIBUTION', undefined);
      }
      return response;
    },
    async getPoolHistory(context) {
      const response = await api.spindle.post('/api/public/pool-history', {
        custom: { assets: 'yarn-algo', platform: 'tinyman' },
      });
      const response2 = await api.spindle.post('/api/public/pool-history', {
        custom: { assets: 'yarn-algo', platform: 'tinyman2' },
      });
      if (response.success && response2.success) {
        context.commit('SET_POOL_HISTORY', { pool1: response.data, pool2: response2.data });
      } else {
        context.commit('SET_POOL_HISTORY', undefined);
      }
      return response;
    },
    async getRewardsTransactions(context, data) {
      const response = await api.spindle.post('/api/private/liquidity-rewards', data);
      if (response.success) {
        response.data = response.data.map((txn) => new Uint8Array(Object.values(txn)));
      }
      return response;
    },
    // eslint-disable-next-line no-unused-vars
    async sendTransactions(context, data) {
      const response = await api.spindle.post('/api/private/send-multiple-transactions', {
        signedTxns: data.signedTxns,
      });
      await api.spindle.post('/api/private/update-distribution-timestamp', { timestamp: context.state.snapShot });
      return response;
    },
    async getAssetInfo(context) {
      try {
        const info = await api.spindle.post('/api/public/asset-info', { option: 'yarn' });
        context.commit('SET_ASSET_INFO', info.data[0]);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    setIsLoading(context, isLoading) {
      context.commit('SET_IS_LOADING', isLoading);
    },
  },
  mutations: {
    SET_LAST_DISTRIBUTION(state, data) {
      if (!data) {
        state.lastDistribution = undefined;
        return;
      }
      state.lastDistribution = data.timestamp;
    },
    SET_POOL_HISTORY(state, history) {
      if (!history) {
        state.decimals = 0;
        state.history = undefined;
        state.snapShot = undefined;
        return;
      }
      const { pool1, pool2 } = history;
      const creation = pool1.shift();

      state.decimals = creation['asset-config-transaction'].params.decimals;

      const creator = creation['asset-config-transaction'].params.creator;
      const histories = utils.getAssetHistoryPerWallet(pool1);
      delete histories[creator];

      const creation2 = pool2.shift();

      const creator2 = creation2['inner-txns'][1]['asset-config-transaction'].params.creator;
      const histories2 = utils.getAssetHistoryPerWallet(pool2);
      delete histories2[creator2];

      state.snapShot = Date.now() / 1000;
      Object.entries(histories).forEach(([k, v]) => {
        const v2 = histories2[k];
        histories[k] = {
          actions: v,
          actions2: v2 || [],
          balance: utils.getWalletBalance(v),
          balance2: v2 ? utils.getWalletBalance(v2) : 0,
          averageBalance: utils.getAverageWalletBalance(v, state.lastDistribution, state.snapShot),
          averageBalance2: v2 ? utils.getAverageWalletBalance(v2, state.lastDistribution, state.snapShot) : 0,
        };
      });
      Object.entries(histories2).forEach(([k2, v2]) => {
        const v = histories[k2];
        if (!v) {
          histories[k2] = {
            actions: [],
            actions2: v2,
            balance: 0,
            balance2: utils.getWalletBalance(v2),
            averageBalance: 0,
            averageBalance2: utils.getAverageWalletBalance(v2, state.lastDistribution, state.snapShot),
          };
        }
      });

      state.history = histories;
    },
    SET_REWARDS_PER_TOKEN(state, amount) {
      state.rewardsPerToken = amount;
    },
    SET_REWARDS_PER_TOKEN_2(state, amount) {
      state.rewardsPerToken2 = amount;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ASSET_INFO(state, info) {
      state.assetId = info.index;
      state.assetDecimals = info.params.decimals;
    },
  },
};

export default liquidity;
