import Vue from 'vue';
import Vuex from 'vuex';
import secureStorage from './secureStorage';
import wallet from './modules/wallet';
import zine from './modules/zine';
import stories from './modules/stories';
import graphicNovels from './modules/graphicNovels';
import pictureBooks from './modules/pictureBooks';
import voting from './modules/voting';
import cards from './modules/cards';
import storefront from './modules/storefront';
import liquidity from './modules/liquidity';
import settings from './modules/settings';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    wallet,
    zine,
    stories,
    graphicNovels,
    pictureBooks,
    voting,
    cards,
    storefront,
    liquidity,
    settings,
  },
  plugins: [secureStorage],
});

export default store;
