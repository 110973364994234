import utils from '../../utils/algorand';
import api from '@/api';

const wallet = {
  namespaced: true,
  state: {
    supportedWallets: ['pera', 'defly', 'exodus'],
    wallet: undefined,
    walletType: undefined,
    account: undefined,
    assets: undefined,
    yarnID: undefined,
    peraConnector: undefined,
    deflyConnector: undefined,
    // myAlgoConnector: undefined,
    reach: undefined,
  },
  getters: {
    shortWallet(state) {
      if (!state.wallet) {
        return undefined;
      }
      return utils.getShortWallet(state.wallet);
    },
  },
  actions: {
    async resetState(context) {
      context.commit('SET_SUPPORTED_WALLETS', ['pera', 'defly', 'exodus']);
      if (context.state.walletType && !context.state.supportedWallets.includes(context.state.walletType)) {
        context.dispatch('setWallet', undefined);
      }
      const response = await api.spindle.post('/api/public/asset-info', { option: 'yarn' });
      if (response.success) {
        context.commit('SET_YARN_ID', response.data);
      }
    },
    setWallet(context, wallet) {
      context.commit('SET_WALLET', wallet);
      if (typeof wallet === 'undefined') {
        context.commit('SET_ACCOUNT_INFO', undefined);
      }
    },
    async getAccountInfo(context, address) {
      if (typeof address === 'undefined') {
        context.commit('SET_ACCOUNT_INFO', undefined);
        return;
      }

      const response = await api.spindle.post('/api/public/account-info', { address });
      if (response.success) {
        context.commit('SET_ACCOUNT_INFO', response.data);
      }
    },
    async getAllAssets(context, address) {
      if (typeof address === 'undefined') {
        context.commit('SET_ASSETS', undefined);
        return;
      }

      const response = await api.spindle.post('/api/public/all-assets', { address });
      if (response.success) {
        context.commit('SET_ASSETS', response.data);
      }
    },
  },
  mutations: {
    SET_WALLET(state, wallet) {
      if (typeof wallet === 'undefined') {
        state.wallet = undefined;
        state.walletType = undefined;
        return;
      }
      state.wallet = wallet.address;
      state.walletType = wallet.type;
    },
    SET_SUPPORTED_WALLETS(state, wallets) {
      state.supportedWallets = wallets;
    },
    SET_ACCOUNT_INFO(state, account) {
      state.account = account;
    },
    SET_ASSETS(state, assets) {
      state.assets = assets;
    },
    SET_YARN_ID(state, assets) {
      state.yarnID = assets[0].index;
    },
  },
};

export default wallet;
