<template>
  <b-navbar type="light" variant="white" class="sp-header px-4 py-0 align-items-stretch" toggleable="xl" sticky>
    <b-navbar-brand href="/" class="px-3 d-flex align-items-center">
      <sp-icon class="sp-navbar-logo py-1" />
      <span class="sp-navbar-text text-primary">{{ $t('app.title') }}</span>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" class="sp-navbar-toggler">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="x"></b-icon>
        <b-icon v-else icon="list"></b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
      :class="{ visible: collapseVisible }"
      @hidden="collapseVisible = false"
      @show="collapseVisible = true"
    >
      <b-navbar-nav>
        <template v-for="(route, idx) in navigation">
          <b-nav-item-dropdown
            v-if="route.children"
            :text="route.title"
            :key="`route-${idx}`"
            :class="{
              'sp-active': isActive(route),
            }"
          >
            <b-dropdown-item
              v-for="(subRoute, subIdx) in route.children"
              :key="`subroute-${subIdx}`"
              :active="isActive(subRoute)"
              :to="subRoute.path"
              >{{ subRoute.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item
            v-else
            :key="idx"
            :href="route.path"
            :link-classes="{
              'd-flex': true,
              'h-100': true,
              'align-items-center': true,
              'sp-active': isActive(route),
            }"
            >{{ route.title }}</b-nav-item
          >
        </template>
        <WalletConnector class="px-3 py-2 d-flex h-100 align-items-center" variant="outline-primary" :noModal="false" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import WalletConnector from '@/components/misc/WalletConnector.vue';

export default {
  name: 'sp-navbar',
  data() {
    return {
      collapseVisible: false,
      navigation: [
        {
          path: '/',
          routes: ['home'],
          title: this.$t('views.home.title'),
        },
        {
          routes: [
            'voting-cards',
            'stories',
            'story',
            'storefront',
            'add-listing',
            'edit-listing',
            'royalties-listing',
          ],
          title: this.$t('views.fiction.title'),
          children: [
            {
              path: '/stories',
              routes: ['stories', 'story'],
              title: this.$t('views.stories.title'),
            },
            {
              path: '/voting-cards',
              routes: ['voting-cards'],
              title: this.$t('views.voting-cards.title'),
            },
            {
              path: '/store',
              routes: ['storefront', 'add-listing', 'edit-listing', 'royalties-listing'],
              title: this.$t('views.storefront.title'),
            },
          ],
        },
        {
          path: '/warp-and-weft',
          routes: ['zine'],
          title: this.$t('views.zine.title'),
        },
        {
          routes: ['ebooks', 'graphic-novels', 'picture-books', 'novels'],
          title: this.$t('views.ebooks.title'),
          children: [
            {
              path: '/graphic-novels',
              routes: ['graphic-novels'],
              title: this.$t('views.graphic-novels.title'),
            },
            {
              path: '/picture-books',
              routes: ['picture-books'],
              title: this.$t('views.picture-books.title'),
            },
            /* {
              path: '/novels',
              routes: ['novels'],
              title: this.$t('views.novels.title'),
            }, */
          ],
        },
        {
          routes: ['token', 'guides', 'staff', 'roadmap'],
          title: this.$t('views.about.title'),
          children: [
            {
              path: '/guides',
              routes: ['guides'],
              title: this.$t('views.guides.title'),
            },
            {
              path: '/whitepaper-roadmap',
              routes: ['roadmap'],
              title: this.$t('views.roadmap.title'),
            },
            {
              path: '/token',
              routes: ['token'],
              title: this.$t('views.token.title'),
            },
            {
              path: '/staff',
              routes: ['staff'],
              title: this.$t('views.staff.title'),
            },
          ],
        },
      ],
    };
  },
  components: {
    WalletConnector,
  },
  methods: {
    isActive(route) {
      return route.routes.includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
.navbar-brand {
  min-height: 54px;
}

.sp-navbar-logo {
  height: 44px;
  color: var(--primary);
}

.sp-navbar-text {
  font-family: 'Quiche', serif !important;
  font-size: 30px;
  margin-left: 4px;
}

/* Toggler */
.sp-navbar-toggler {
  border: unset;
}
.sp-navbar-toggler * {
  font-size: 1.5rem !important;
}

#nav-collapse.visible * {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>

<style>
.sp-header .dropdown .nav-link {
  height: 100%;
  display: flex;
  align-items: center;
}
.sp-header .dropdown-toggle::after {
  margin-left: 0.5rem;
}

#nav-collapse ul.dropdown-menu {
  margin: 0px;
  border: unset;
  border-radius: 0px;
  padding: 8px;
}

#nav-collapse.visible .dropdown-item {
  max-width: max-content;
  padding: 4px 8px;
}

#nav-collapse .dropdown-item:hover,
#nav-collapse .dropdown-item.active {
  background: unset;
}
#nav-collapse:not(.visible) .dropdown-item {
  max-width: 100%;
  padding: 4px 8px;
}
</style>
