import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueMeta from 'vue-meta';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Icon from '@/components/misc/Icon';
import Decoration from '@/components/misc/Decoration';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRedditAlien, faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faCartShopping,
  faCheckToSlot,
  faHandHoldingDollar,
  faHandshakeSimple,
  faFilter,
  faSearch,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowDown19,
  faArrowDown91,
  faRotate,
  faBook,
  faFlagCheckered,
  faPenNib,
  faPaintBrush,
  faInfoCircle,
  faGears,
  faUsers,
  faFileLines,
  faPlus,
  faMinus,
  faHashtag,
  faFont,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// add bootstrap
Vue.use(BootstrapVue);

// add icons
library.add(
  faRedditAlien,
  faDiscord,
  faTwitter,
  faCartShopping,
  faCheckToSlot,
  faHandHoldingDollar,
  faHandshakeSimple,
  faFilter,
  faSearch,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowDown19,
  faArrowDown91,
  faRotate,
  faBook,
  faFlagCheckered,
  faPenNib,
  faPaintBrush,
  faInfoCircle,
  faGears,
  faUsers,
  faFileLines,
  faPlus,
  faMinus,
  faHashtag,
  faFont,
  faBan
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(BootstrapVueIcons);
Vue.component('sp-icon', Icon);
Vue.component('sp-decoration', Decoration);

Vue.use(VueMeta);

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
