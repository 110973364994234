<template>
  <div class="sp-wallet-connector">
    <b-button v-if="!wallet" :variant="variant" @click="$bvModal.show('modal-connect-wallet')">{{
      $t('components.misc.walletConnector.connect')
    }}</b-button>
    <b-button
      v-else
      :variant="variant"
      :disabled="isLoading"
      @click="$bvModal.show('modal-disconnect-wallet')"
      class="sp-wallet-button"
    >
      <span>{{ shortWallet[0] }}</span>
      <sp-icon width="16px" />
      <span>{{ shortWallet[1] }}</span>
    </b-button>
    <b-modal
      v-if="!noModal"
      id="modal-connect-wallet"
      :title="$t('components.misc.walletConnector.connect')"
      hide-footer
      dialog-class="sp-max-content"
      body-class="d-flex flex-column align-items-center"
      centered
    >
      <b-button
        variant="outline-dark"
        class="m-1 w-100 text-left d-flex align-items-center"
        @click="connectPeraWallet()"
        ><sp-icon name="pera" width="28px" height="28px" class="mr-2" />{{
          $t('components.misc.walletConnector.pera')
        }}</b-button
      >
      <b-button
        variant="outline-dark"
        class="m-1 w-100 text-left d-flex align-items-center"
        @click="connectDeflyWallet()"
        ><sp-icon name="defly" width="28px" height="28px" class="mr-2" />{{
          $t('components.misc.walletConnector.defly')
        }}</b-button
      >
      <!--b-button
        variant="outline-dark"
        class="m-1 w-100 text-left d-flex align-items-center"
        @click="connectMyAlgoWallet()"
        ><sp-icon name="myalgo" width="28px" height="28px" class="mr-2" />{{
          $t('components.misc.walletConnector.myalgo')
        }}</b-button
      -->
      <b-button
        variant="outline-dark"
        class="m-1 w-100 text-left d-flex align-items-center"
        @click="connectExodusWallet()"
        ><sp-icon name="exodus" width="28px" height="28px" class="mr-2" />{{
          $t('components.misc.walletConnector.exodus')
        }}</b-button
      >
    </b-modal>
    <b-modal
      v-if="!noModal"
      id="modal-disconnect-wallet"
      :title="$t('components.misc.walletConnector.options')"
      hide-footer
      dialog-class="sp-max-content"
      body-class="d-flex flex-column align-items-center"
      centered
    >
      <h6 v-if="wallet">
        <span>{{ shortWallet[0] }}</span>
        <sp-icon width="16px" />
        <span>{{ shortWallet[1] }}</span>
      </h6>
      <b-button variant="outline-danger" class="m-1 w-100" @click="disconnectWallet()">{{
        $t('components.misc.walletConnector.disconnect')
      }}</b-button>
    </b-modal>
  </div>
</template>

<script>
// import MyAlgoConnect from '@randlabs/myalgo-connect';
import { PeraWalletConnect } from '@perawallet/connect';
import { DeflyWalletConnect } from '@blockshake/defly-connect';
import createConnector from '@/utils/connector';
import algosdk from 'algosdk';

import { loadStdlib } from '@reach-sh/stdlib';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'sp-wallet-connector',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    noModal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      shortWallet: 'wallet/shortWallet',
    }),
    ...mapState({
      wallet: (state) => state.wallet.wallet,
      walletType: (state) => state.wallet.walletType,
      isLoading: (state) => {
        // all modules that depend on the wallet should be checked here
        return state.zine.isLoading || state.voting.isLoading;
      },
      peraConnector: (state) => state.wallet.peraConnector,
      deflyConnector: (state) => state.wallet.deflyConnector,
      // myAlgoConnector: (state) => state.wallet.myAlgoConnector,
    }),
  },
  async created() {
    this.setConnector();

    if (['pera', 'defly'].includes(this.walletType)) {
      const connector = this[`${this.walletType}Connector`];
      try {
        await connector.reconnectSession();

        connector.connector?.on('disconnect', this.disconnectWallet);
      } catch (error) {
        console.error(error);
        this.disconnectWallet();
      }
    }
  },
  methods: {
    async setConnector() {
      // Create a connector
      this.$store.state.wallet.peraConnector = new PeraWalletConnect();
      this.$store.state.wallet.deflyConnector = new DeflyWalletConnect();

      const providerEnv = process.env.VUE_APP_PROVIDER_ENV === 'development' ? 'algonode/TestNet' : 'algonode/MainNet';

      const CustomAlgoConnector = createConnector(this);
      const reach = loadStdlib({ REACH_CONNECTOR_MODE: 'ALGO' });
      const wf = reach.walletFallback({ providerEnv: providerEnv, MyAlgoConnect: CustomAlgoConnector });
      const wallet = wf();
      const truthyEnv = (v) => {
        if (!v) return false;
        return !['0', 'false', 'f', '#f', 'no', 'off', 'n', ''].includes(v && v.toLowerCase && v.toLowerCase());
      };
      const makeProviderByWallet = async (wallet, env) => {
        const defaults = { REACH_ISOLATED_NETWORK: 'no', ALGO_NODE_WRITE_ONLY: 'yes' }; // pessimistic
        const allEnv = { ...defaults, ...env, ...(wallet._env || {}) }; // rightmost is preferred
        const { ALGO_GENESIS_ID, ALGO_GENESIS_HASH, ALGO_ACCOUNT } = env;
        const { REACH_ISOLATED_NETWORK, ALGO_NODE_WRITE_ONLY } = allEnv;
        const walletOpts = {
          genesisID: ALGO_GENESIS_ID || undefined,
          genesisHash: ALGO_GENESIS_HASH || undefined,
          accounts: ALGO_ACCOUNT ? [ALGO_ACCOUNT] : undefined,
        };
        const isIsolatedNetwork = truthyEnv(REACH_ISOLATED_NETWORK);
        const nodeWriteOnly = truthyEnv(ALGO_NODE_WRITE_ONLY);
        let enabledNetwork;
        let enabledAccounts;
        if (wallet.enableNetwork === undefined && wallet.enableAccounts === undefined) {
          const enabled = await wallet.enable(walletOpts);
          enabledNetwork = enabled;
          enabledAccounts = enabled;
        } else if (wallet.enableNetwork === undefined || wallet.enableAccounts === undefined) {
          throw new Error('must have enableNetwork AND enableAccounts OR neither');
        } else {
          enabledNetwork = await wallet.enableNetwork(walletOpts);
        }
        void enabledNetwork;
        const algod_bc = await wallet.getAlgodv2Client();
        const indexer_bc = await wallet.getIndexerClient();
        const algodClient = new algosdk.Algodv2(algod_bc);
        const indexer = new algosdk.Indexer(indexer_bc);
        const getDefaultAddress = async () => {
          if (enabledAccounts === undefined) {
            if (wallet.enableAccounts === undefined) {
              throw new Error('impossible: no wallet.enableAccounts');
            }
            enabledAccounts = await wallet.enableAccounts(walletOpts);
            if (enabledAccounts === undefined) {
              throw new Error('Could not enable accounts');
            }
          }
          return enabledAccounts.accounts[0];
        };
        const signAndPostTxns = wallet.signAndPostTxns;
        return {
          algod_bc,
          indexer_bc,
          indexer,
          algodClient,
          nodeWriteOnly,
          getDefaultAddress,
          isIsolatedNetwork,
          signAndPostTxns,
        };
      };
      const provider = await makeProviderByWallet(wallet, process.env);
      reach.setProvider(provider);
      this.$store.state.wallet.reach = reach;
    },
    async connectExodusWallet() {
      let accountsSharedByUser;
      try {
        if (!window.exodus?.algorand) {
          throw new Error(this.$t('components.misc.walletConnector.noExodus'));
        }
        accountsSharedByUser = await window.exodus.algorand.connect();
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          autoHideDelay: 5000,
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
          solid: true,
        });
        return;
      }
      this.$store.dispatch('wallet/setWallet', {
        address: accountsSharedByUser.address,
        type: 'exodus',
      });
      this.$bvModal.hide('modal-connect-wallet');
    },
    /* async connectMyAlgoWallet() {
      let accountsSharedByUser;
      try {
        accountsSharedByUser = await this.myAlgoConnector.connect({
          shouldSelectOneAccount: true,
        });
      } catch (error) {
        console.error(error);
        return;
      }
      this.$store.dispatch('wallet/setWallet', {
        address: accountsSharedByUser[0].address,
        type: 'my-algo',
      });
      this.$bvModal.hide('modal-connect-wallet');
    }, */
    async connectPeraWallet() {
      try {
        await this.peraConnector.disconnect();
        const newAccounts = await this.peraConnector.connect();
        this.peraConnector.connector?.on('disconnect', this.disconnectWallet);
        this.$store.dispatch('wallet/setWallet', {
          address: newAccounts[0],
          type: 'pera',
        });
        this.$bvModal.hide('modal-connect-wallet');
      } catch (error) {
        console.error(error);
        if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
          this.$bvToast.toast(error.message, {
            title: this.$t('misc.error'),
            autoHideDelay: 10000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-center',
            bodyClass: 'text-break',
            solid: true,
          });
        }
        return;
      }
    },
    async connectDeflyWallet() {
      try {
        await this.deflyConnector.disconnect();
        const newAccounts = await this.deflyConnector.connect();
        this.deflyConnector.connector?.on('disconnect', this.disconnectWallet);
        this.$store.dispatch('wallet/setWallet', {
          address: newAccounts[0],
          type: 'defly',
        });
        this.$bvModal.hide('modal-connect-wallet');
      } catch (error) {
        console.error(error);
        if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
          this.$bvToast.toast(error.message, {
            title: this.$t('misc.error'),
            autoHideDelay: 10000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-center',
            bodyClass: 'text-break',
            solid: true,
          });
        }
        return;
      }
    },
    async disconnectWallet() {
      if (['pera', 'defly'].includes(this.walletType)) {
        const connector = this[`${this.walletType}Connector`];
        try {
          await connector.disconnect();
        } catch (error) {
          console.error(error);
        }
      }
      this.$store.dispatch('wallet/setWallet', undefined);
      this.$bvModal.hide('modal-disconnect-wallet');
    },
  },
  watch: {
    wallet: {
      handler(newValue) {
        this.$store.dispatch('wallet/getAccountInfo', newValue);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.pera-wallet-connect-modal,
.pera-wallet-sign-txn-toast {
  z-index: 10000 !important;
}
</style>
