const normalizeString = (str) => {
  return str.replaceAll(/\W/g, ' ').replaceAll(/ +/g, '-').toLowerCase();
};

const onPeraClose = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const toasts = document.getElementsByTagName('pera-wallet-sign-txn-toast');
      for (let i = 0; i < toasts.length; i++) {
        const toast = toasts[i].shadowRoot;
        toast.children[0].style.zIndex = '10000';
        const button = toast.getElementById('pera-wallet-sign-txn-toast-close-button');
        button.onclick = () => {
          reject(new Error('Transactions cancelled by User.'));
        };
      }
    });
  });
};

const onDeflyClose = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const toasts = document.getElementsByTagName('defly-wallet-sign-txn-toast');
      for (let i = 0; i < toasts.length; i++) {
        const toast = toasts[i].shadowRoot;
        toast.children[0].style.zIndex = '10000';
        const button = toast.getElementById('defly-wallet-sign-txn-toast-close-button');
        button.onclick = () => {
          reject(new Error('Transactions cancelled by User.'));
        };
      }
    });
  });
};

const signTxnErrorHandler = (error, that) => {
  console.error(error);
  let message = error.message;
  if (message.includes('missing') || message.includes('underflow')) {
    message = that.$t('misc.errorMessages.underflow');
  } else if (message.includes('overspend')) {
    message = that.$t('misc.errorMessages.overspend');
  } else if (message.includes('cancelled')) {
    message = that.$t('misc.errorMessages.cancelled');
  }
  // replace reach error messages
  message = message.split('\n')[0].replace(/^.+:/, '');
  that.$bvToast.toast(message, {
    title: that.$t('misc.error'),
    autoHideDelay: 10000,
    variant: 'danger',
    toaster: 'b-toaster-bottom-center',
    bodyClass: 'text-break',
    solid: true,
  });
};

export { normalizeString, onPeraClose, onDeflyClose, signTxnErrorHandler };
