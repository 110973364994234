<template>
  <div class="home sp-banner d-flex flex-column">
    <div class="sp-banner-content d-flex flex-column">
      <div class="sp-banner-top">
        <div class="sp-banner-text">
          <div class="d-flex">
            <sp-icon class="sp-spindle-logo" />
            <h1 class="sp-spindle">{{ $t('app.title') }}</h1>
          </div>
          <h4 class="sp-spindle-subtitle">{{ $t('views.home.text.story') }}</h4>
        </div>
      </div>
      <div class="sp-banner-bottom">
        <div class="sp-banner-text my-auto ml-auto">
          <h1 class="text-right">{{ $t('views.home.text.algorand_1') }}</h1>
          <h2 class="text-right">{{ $t('views.home.text.algorand_2') }}</h2>
        </div>
      </div>
    </div>

    <div class="sp-home">
      <div class="py-5 d-flex flex-column align-items-center bg-light text-center px-4">
        <h1 class="sp-headline">{{ $t('views.home.text.stories') }}</h1>
        <h5 class="mt-4">{{ $t('views.home.text.description') }}</h5>
        <b-button class="mt-5" variant="outline-primary" href="/stories">{{
          $t('views.home.button.stories')
        }}</b-button>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="sp-home-content">
          <div
            class="sp-home-text"
            v-for="item in [
              ['tipping', 'fa-hand-holding-dollar'],
              ['partnerships', 'fa-handshake-simple'],
              ['interaction', 'fa-check-to-slot'],
              ['merchandise', 'fa-cart-shopping'],
            ]"
            :key="item[0]"
          >
            <font-awesome-icon :icon="item[1]" class="fa-5x p-3" />
            <h3>{{ $t(`views.home.headline.${item[0]}`) }}</h3>
            <p>{{ $t(`views.home.text.${item[0]}`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
};
</script>

<style scoped>
@keyframes animateleft {
  from {
    left: -100vw;
    opacity: 0;
  }
  20% {
    right: -100vw;
    opacity: 1;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes animateright {
  0% {
    right: -100vw;
    opacity: 0;
  }
  40% {
    right: -100vw;
    opacity: 1;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.sp-banner {
  overflow-x: hidden;
  box-shadow: inset 0 0 10px #121009;
}
.sp-banner-top {
  position: relative;
  animation: animateleft 2.4s;
}
.sp-banner-bottom {
  margin-top: auto;
  position: relative;
  animation: animateright 3s;
}

.sp-spindle-logo {
  width: 50px;
}
.sp-spindle {
  font-size: 56px;
  position: relative;
  top: -3px;
  left: 3.5px;
  margin: 0;
}
.sp-spindle-subtitle {
  font-family: 'Quiche';
  position: relative;
  text-align: left;
  top: -17px;
  font-size: 18px;
  left: 110px;
}
.sp-banner-text {
  padding: 36px 21px;
  position: relative;
  font-family: 'Montserrat', sans-serif;
}

.sp-banner-text {
  filter: drop-shadow(0px 0px 1px #121009) drop-shadow(0px 0px 1px #121009bb) drop-shadow(0px 0px 1px #12100988)
    drop-shadow(0px 0px 5px #121009);
}

.sp-banner-bottom h1 {
  font-size: 35px;
  margin-left: auto;
}
.sp-banner-bottom h2 {
  font-size: 22px;
}

@media (min-width: 430px) {
  .sp-spindle-logo {
    width: 60px;
  }
  .sp-spindle {
    font-size: 67px;
    top: -3.6px;
    left: 4px;
  }
  .sp-spindle-subtitle {
    top: -20px;
    font-size: 22px;
    left: 132px;
  }
  .sp-banner-text {
    padding: 43px 25px;
  }
  .sp-banner-bottom h1 {
    font-size: 42px;
    max-width: 400px;
  }
  .sp-banner-bottom h2 {
    font-size: 26px;
  }
}
@media (min-width: 576px) {
  .sp-spindle-logo {
    width: 75px;
  }
  .sp-spindle {
    font-size: 84px;
    top: -4.5px;
    left: 5px;
  }
  .sp-spindle-subtitle {
    top: -25px;
    font-size: 27px;
    left: 165px;
  }
  .sp-banner-text {
    padding: 54px 32px;
  }
  .sp-banner-bottom h1 {
    font-size: 52px;
    max-width: 400px;
  }
  .sp-banner-bottom h2 {
    font-size: 33px;
  }
}
@media (min-width: 768px) {
  .sp-spindle-logo {
    width: 100px;
  }
  .sp-spindle {
    font-size: 112px;
    top: -6px;
    left: 7px;
  }
  .sp-spindle-subtitle {
    top: -34px;
    font-size: 36px;
    left: 220px;
  }
  .sp-banner-text {
    padding: 72px 42px;
  }
  .sp-banner-bottom h1 {
    font-size: 60px;
    max-width: 600px;
  }
  .sp-banner-bottom h2 {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .sp-banner-bottom h1 {
    max-width: unset;
  }
}
@media (min-width: 1600px) {
  .sp-spindle-logo {
    width: 6vw;
  }
  .sp-spindle {
    font-size: 6.7vw;
    top: -0.36vw;
    left: 0.4vw;
  }
  .sp-spindle-subtitle {
    top: -2vw;
    font-size: 2.1vw;
    left: 13.8vw;
  }
  .sp-banner-text {
    padding: 4.3vw 2.5vw;
  }
  .sp-banner-bottom h1 {
    font-size: 4.2vw;
  }
  .sp-banner-bottom h2 {
    font-size: 2.6vw;
  }
}

.sp-banner-top .sp-banner-text {
  padding-bottom: 0;
}
.sp-banner-bottom .sp-banner-text {
  padding-top: 0;
}

.sp-banner {
  min-height: 100%;
  min-width: 100%;
}
.sp-banner-content {
  min-height: max-content;
}
.sp-home {
  background: var(--background);
}
.sp-home-content {
  max-width: 1280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.sp-home-text {
  margin: 60px;
  max-width: 520px;
  box-sizing: border-box;
  text-align: center;
}
</style>
