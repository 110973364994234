module.exports = {
  COOKIES: {
    CONSENT: 'sp_consent',
    FUNCTIONAL: 'sp_functional',
    PERFORMANCE: 'sp_performance',
    SESSION: 'sp_sid',
    LOCAL_STORAGE: 'sp_ls',
    SECURITY_WARNING: 'sp_security_warning_1',
    EXPIRATION: 365,
  },
};
