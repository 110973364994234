<template>
  <b-navbar type="light" variant="white" class="sp-footer px-4 py-0">
    <b-navbar-nav class="d-flex align-items-center">
      <span class="px-3 py-2">© 2022 Spindle ASA</span>
      <b-button
        variant="link"
        class="ml-4 nav-link sp-mode"
        :class="{ 'sp-disabled': mode === 'dark' }"
        @click="setMode('light')"
      >
        <b-icon :icon="mode === 'light' ? 'sun-fill' : 'sun'" />
      </b-button>
      <b-button
        variant="link"
        class="nav-link sp-mode"
        :class="{ 'sp-disabled': mode === 'light' }"
        @click="setMode('dark')"
      >
        <b-icon :icon="mode === 'dark' ? 'moon-fill' : 'moon'" />
      </b-button>
    </b-navbar-nav>

    <div class="d-flex flex-wrap justify-content-center">
      <b-navbar-nav class="px-3 py-2">
        <b-nav-item link-classes="px-0" href="/privacy-policy">{{ $t('views.privacy.title') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="px-3 py-2">
        <b-nav-item link-classes="px-0" href="/cookie-policy">{{ $t('views.cookies.title') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="px-3 py-2">
        <b-nav-item link-classes="px-0" href="/contact">{{ $t('views.contact.title') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="px-3 py-2">
        <b-nav-item link-classes="px-0" href="https://discord.com/invite/Wr9AFCdtNr" target="_blank">
          <font-awesome-icon icon="fa-brands fa-discord" class="fa-xl" />
        </b-nav-item>
        <b-nav-item link-classes="px-0 ml-3" href="https://twitter.com/ASASpindle" target="_blank">
          <font-awesome-icon icon="fa-brands fa-twitter" class="fa-xl" />
        </b-nav-item>
        <b-nav-item link-classes="px-0 ml-3" href="https://www.reddit.com/r/SpindleASA" target="_blank">
          <font-awesome-icon icon="fa-brands fa-reddit-alien" class="fa-xl" />
        </b-nav-item>
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'sp-footer',
  computed: {
    ...mapState({
      mode: (state) => state.settings.mode,
    }),
  },
  mounted() {
    this.setMode(this.mode);
  },
  methods: {
    setMode(newMode) {
      // get current stylesheet and mode
      const links = document.getElementsByTagName('link');
      const stylesheets = [...links].filter((item) => {
        return /\/css\/(\w+)_theme.css/.test(item.href);
      });
      const currentMode = /\/css\/(\w+)_theme.css/.exec(stylesheets[1].href)[1];

      // remove and add new stylesheet if mode changed
      if (newMode && currentMode !== newMode) {
        stylesheets[0].remove();
        document.head.appendChild(stylesheets[0]);
      } else if (!newMode) {
        newMode = currentMode;
      }

      this.$store.dispatch('settings/setMode', newMode);
    },
  },
};
</script>

<style scoped>
.sp-navbar-logo {
  height: 50px;
  color: var(--primary);
}

.sp-mode {
  color: var(--dark) !important;
  opacity: 0.8;
}
.sp-mode.sp-disabled {
  opacity: 0.5;
}
.sp-mode.sp-disabled:hover {
  opacity: 0.8;
}

.sp-mode:focus {
  box-shadow: unset;
}
</style>
