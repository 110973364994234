<template>
  <svg
    class="sp-decoration"
    xmlns="http://www.w3.org/2000/svg"
    width="0.847222in"
    height="0.541667in"
    viewBox="0 0 61 39"
  >
    <path
      fill="currentColor"
      d="M 53.51,18.17
           C 55.50,15.04 60.25,2.54 58.49,1.53
             55.99,0.39 53.56,14.87 53.51,18.17 Z
           M 31.14,21.42
           C 30.79,17.69 23.99,2.65 21.77,3.63
             19.50,4.78 28.14,19.35 31.14,21.42 Z
           M 1.88,29.83
           C 2.87,27.73 14.10,33.94 16.61,36.53
             13.38,36.26 0.83,32.00 1.88,29.83 Z
           M 21.39,26.58
           C 20.18,26.48 14.81,24.65 15.65,23.33
             16.58,22.00 20.89,25.59 21.39,26.58 Z
           M 43.76,16.45
           C 44.50,15.15 45.60,11.55 44.34,11.48
             42.95,11.41 43.28,15.26 43.76,16.45 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'sp-decoration',
};
</script>
