<template>
  <div class="sp-consent-banner-wrapper">
    <b-alert :show="showBanner" variant="primary" fade
      ><div class="d-flex flex-wrap justify-content-center sp-consent-banner">
        <div class="sp-consent-description p-1">
          <span v-html="$t('app.consent.description')" />
        </div>
        <div class="d-flex flex-column sp-consent-selection align-items-center justify-content-center p-1">
          <div>
            <b-button size="sm" class="mr-2" variant="outline-primary" @click="decline">{{
              $t('app.consent.button.declineAll')
            }}</b-button>
            <b-button v-if="!showOptions" size="sm" variant="primary" @click="acceptAll">{{
              $t('app.consent.button.acceptAll')
            }}</b-button>
            <b-button v-else size="sm" variant="primary" @click="acceptSelection">{{
              $t('app.consent.button.acceptSelection')
            }}</b-button>
          </div>
          <b-button size="sm" variant="link" class="mt-2 d-flex align-items-center" @click="showOptions = !showOptions"
            >{{ $t('app.consent.text.options')
            }}<b-icon class="ml-1" :icon="`caret-${showOptions ? 'down' : 'right'}-fill`" font-scale="0.9"
          /></b-button>
          <b-collapse :visible="showOptions">
            <b-form-checkbox :checked="true" :disabled="true" switch>{{
              $t('app.consent.text.necessary')
            }}</b-form-checkbox>
            <b-form-checkbox v-model="allowFunctionalCookies" switch>{{
              $t('app.consent.text.functional')
            }}</b-form-checkbox>
            <b-form-checkbox v-model="allowPerformanceCookies" switch>{{
              $t('app.consent.text.performance')
            }}</b-form-checkbox>
          </b-collapse>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { v4 } from 'uuid';
import constants from '@/utils/constants';

export default {
  name: 'sp-consent-banner',
  data() {
    return {
      showBanner: false,
      showOptions: false,
      allowFunctionalCookies: false,
      allowPerformanceCookies: false,
    };
  },
  created() {
    try {
      const consent = JSON.parse(Cookie.get(constants.COOKIES.CONSENT));
      // if there is no consent, remove (just to make sure they're gone)
      this.allowFunctionalCookies = consent['functional'];
      this.allowPerformanceCookies = consent['performance'];
      this.acceptSelection();
    } catch (err) {
      // if there is anything "wrong" with the cookies, remove all (for safety)
      if (Cookie.get(constants.COOKIES.CONSENT)) {
        Cookie.remove(constants.COOKIES.CONSENT);
      }
      if (Cookie.get(constants.COOKIES.FUNCTIONAL)) {
        Cookie.remove(constants.COOKIES.FUNCTIONAL);
      }
      if (Cookie.get(constants.COOKIES.PERFORMANCE)) {
        Cookie.remove(constants.COOKIES.PERFORMANCE);
      }
      this.allowFunctionalCookies = false;
      this.allowPerformanceCookies = false;
      this.showBanner = true;
    }
    this.$eventBus.$on('sp-open-consent', () => {
      this.showBanner = true;
    });
  },
  methods: {
    acceptAll() {
      this.allowFunctionalCookies = true;
      this.allowPerformanceCookies = true;
      this.acceptSelection();
    },
    acceptSelection() {
      const consent = {
        functional: this.allowFunctionalCookies,
        performance: this.allowPerformanceCookies,
        consentDate: Date.now(),
      };

      // set consent cookie
      Cookie.set(constants.COOKIES.CONSENT, JSON.stringify(consent), { expires: constants.COOKIES.EXPIRATION });

      // set the functional cookie
      if (this.allowFunctionalCookies) {
        const value = Cookie.get(constants.COOKIES.FUNCTIONAL) || Cookie.get(constants.COOKIES.SESSION) || v4();
        Cookie.set(constants.COOKIES.FUNCTIONAL, value, { expires: constants.COOKIES.EXPIRATION });
      } else {
        if (Cookie.get(constants.COOKIES.FUNCTIONAL)) {
          Cookie.remove(constants.COOKIES.FUNCTIONAL);
        }
      }

      // set the performance cookie
      if (this.allowPerformanceCookies) {
        const value = Cookie.get(constants.COOKIES.PERFORMANCE) || v4();
        Cookie.set(constants.COOKIES.PERFORMANCE, value, { expires: constants.COOKIES.EXPIRATION });
      } else {
        if (Cookie.get(constants.COOKIES.PERFORMANCE)) {
          Cookie.remove(constants.COOKIES.PERFORMANCE);
        }
      }
      this.showBanner = false;
    },
    decline() {
      this.allowFunctionalCookies = false;
      this.allowPerformanceCookies = false;
      setTimeout(() => {
        this.acceptSelection();
      }, 500);
    },
  },
};
</script>

<style scoped>
.sp-consent-banner-wrapper {
  margin: 0 auto;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2rem;
  pointer-events: none;
  z-index: 1000;
}
.sp-consent-banner {
  max-width: 1000px;
  pointer-events: all;
}
.sp-consent-description {
  min-width: 250px;
  font-size: 14px;
  flex: 1;
}
.sp-consent-selection {
  min-width: 250px;
}
.btn.btn-link:focus {
  box-shadow: unset;
}
</style>
<style>
.sp-consent-selection .custom-control-label {
  font-size: 14px;
  position: relative;
  padding-top: 1px;
}
.sp-consent-banner a,
.sp-policy-content a:hover {
  color: var(--cyan) !important;
}
</style>
