import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import constants from '@/utils/constants';
import { v4 } from 'uuid';

// Get the encryption token from cookie (session or permanent functionals) or generate a new one.
let encryptionToken = Cookie.get(constants.COOKIES.FUNCTIONAL);
if (!encryptionToken) {
  encryptionToken = Cookie.get(constants.COOKIES.SESSION) || v4();
  // Store the encryption token in a secure cookie.
  Cookie.set(constants.COOKIES.SESSION, encryptionToken, {});
}

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(constants.COOKIES.LOCAL_STORAGE);

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken);

          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(constants.COOKIES.LOCAL_STORAGE);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();

      // Save the encrypted store in local storage.
      return window.localStorage.setItem(constants.COOKIES.LOCAL_STORAGE, store);
    },
    removeItem: () => window.localStorage.removeItem(constants.COOKIES.LOCAL_STORAGE),
  },
});

export default vuexLocal.plugin;
