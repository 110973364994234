const settings = {
  namespaced: true,
  state: {
    mode: undefined,
  },
  actions: {
    setMode(context, mode) {
      context.commit('SET_MODE', mode);
    },
  },
  mutations: {
    SET_MODE(state, mode) {
      state.mode = mode;
    },
  },
};

export default settings;
