import api from '@/api';

const graphicNovels = {
  namespaced: true,
  state: {
    availableGraphicNovels: undefined,
    searchSettings: {
      orderBy: 'updated',
      orderDirection: 'desc',
      searchFor: 'name',
      searchQuery: '',
    },
  },
  actions: {
    async resetState(context) {
      const response = await api.spindle.get('/api/public/get-graphic-novels');
      context.commit('SET_AVAILABLE_PUBLICATIONS', response.data);

      context.commit('SET_IS_LOADING', false);
    },
    setSearchSetting(context, { setting, value }) {
      context.commit('SET_SEARCH_SETTING', { setting, value });
    },
    async getPDF(_, data) {
      let response = await api.spindle.post('/api/private/get-graphic-novel-pdf', data, { responseType: 'blob' });
      if (response.success) {
        response.data = URL.createObjectURL(response.data);
      }
      return response;
    },
    // data: { app, graphicNovel, issue, version, other }
    async addListing(context, data) {
      const response = await api.spindle.post('/api/private/graphic-novel-add-listing', data);
      return response;
    },
    // data: { app }
    async closeListing(context, data) {
      const response = await api.spindle.post('/api/private/graphic-novel-close-listing', data);
      return response;
    },
    setIsLoading(context, isLoading) {
      context.commit('SET_IS_LOADING', isLoading);
    },
  },
  mutations: {
    SET_AVAILABLE_PUBLICATIONS(state, availableGraphicNovels) {
      if (!availableGraphicNovels) {
        state.availableGraphicNovels = [];
        return;
      }
      state.availableGraphicNovels = availableGraphicNovels;
    },
    SET_SEARCH_SETTING(state, { setting, value }) {
      state.searchSettings[setting] = value;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};

export default graphicNovels;
