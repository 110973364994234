import api from '@/api';

const storefronts = {
  namespaced: true,
  state: {
    isLoading: false,
    listings: undefined,
    searchSettings: {
      orderBy: 'listing',
      orderDirection: 'desc',
      searchFor: 'name',
      searchQuery: '',
    },
  },
  actions: {
    async resetState(context) {
      const response = await api.spindle.get('/api/private/storefronts');
      if (response.success) {
        context.commit('SET_LISTINGS', response.data);
      }
      context.commit('SET_IS_LOADING', false);
      return response;
    },
    // data: { app, story, version, other }
    async addListing(context, data) {
      const response = await api.spindle.post('/api/private/add-listing', data);
      return response;
    },
    // data: { app }
    async closeListing(context, data) {
      const response = await api.spindle.post('/api/private/close-listing', data);
      return response;
    },
    setSearchSetting(context, { setting, value }) {
      context.commit('SET_SEARCH_SETTING', { setting, value });
    },
    setIsLoading(context, isLoading) {
      context.commit('SET_IS_LOADING', isLoading);
    },
  },
  mutations: {
    SET_LISTINGS(state, listings) {
      state.listings = listings;
    },
    SET_SEARCH_SETTING(state, { setting, value }) {
      state.searchSettings[setting] = value;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};

export default storefronts;
