import axios from 'axios';
import api from '@/api';
import { normalizeString } from '@/utils/misc';

const stories = {
  namespaced: true,
  state: {
    availableStories: [],
    readingSettings: {
      selectedFont: 'Roboto',
      selectedAlignment: 'left',
      fontSize: 1,
      lineHeight: 1.332,
      margin: 5,
    },
    searchSettings: {
      orderBy: 'updated',
      orderDirection: 'desc',
      searchFor: 'name',
      searchQuery: '',
    },
  },
  actions: {
    async resetState(context) {
      const response = await api.spindle.get('/api/public/get-stories');
      if (response.success) {
        context.commit('SET_AVAILABLE_STORIES', response.data);
      }
    },
    async getStory(context, { storyId, chapterId }) {
      const story = context.state.availableStories.find((story) => story.id === storyId);
      if (!story) throw new Error('Story could not be found.');

      let chapter = story.chapters.find(
        (chapter) => encodeURIComponent(decodeURIComponent(chapter.id)) === encodeURIComponent(chapterId)
      );
      if (!chapter) {
        chapter = story.chapters.find((chapter) => normalizeString(chapter.name) === normalizeString(chapterId));
      }
      if (!chapter) throw new Error('Chapter could not be found.');

      return { story, chapter };
    },
    async getContent(context, url) {
      let response;
      try {
        response = await axios.get(url);
        response = response.data;
      } catch (error) {
        console.error(error);
        response = error;
      }
      return response;
    },
    setReadingSetting(context, { setting, value }) {
      context.commit('SET_READING_SETTING', { setting, value });
    },
    setSearchSetting(context, { setting, value }) {
      context.commit('SET_SEARCH_SETTING', { setting, value });
    },
  },
  mutations: {
    SET_AVAILABLE_STORIES(state, availableStories) {
      if (!availableStories) {
        state.availableStories = [];
        return;
      }
      state.availableStories = availableStories;
    },
    SET_READING_SETTING(state, { setting, value }) {
      state.readingSettings[setting] = value;
    },
    SET_SEARCH_SETTING(state, { setting, value }) {
      state.searchSettings[setting] = value;
    },
  },
};

export default stories;
