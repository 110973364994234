<template>
  <div id="app" :class="{ home: this.$route.name === 'home', 'sp-custom-bg': this.$route.name === 'story' }">
    <div id="app-wrapper">
      <Navbar />
      <div id="app-content">
        <router-view />
      </div>
      <ConsentBanner />
      <Footer />
    </div>
    <div v-if="isLoading" class="sp-overlay">
      <b-spinner style="width: 5rem; height: 5rem"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from './components/app/Navbar.vue';
import Footer from './components/app/Footer.vue';
import ConsentBanner from './components/app/ConsentBanner.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    ConsentBanner,
  },
  metaInfo() {
    let title;
    if (this.routeName === '') {
      title = this.$t('app.title');
    } else {
      title = `${this.$t(`views.${this.routeName}.title`)} | ${this.$t('app.title')}`;
    }
    return {
      title,
    };
  },
  data() {
    return {
      interval: undefined,
    };
  },
  created() {
    [
      'wallet',
      'zine',
      'stories',
      'voting',
      'cards',
      'liquidity',
      'graphicNovels',
      'pictureBooks',
      'storefront',
    ].forEach((storeModule) => {
      this.$store.dispatch(`${storeModule}/resetState`);
    });
  },
  mounted() {
    // fix for urlbar on phones messing with layout
    this.setInnerHeight();
    this.interval = setInterval(() => {
      this.setInnerHeight();
    }, 100);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState({
      isLoading: (state) => {
        // all modules that require a fullscreen loading overlay should be added here
        return (
          state.zine.isLoading ||
          state.voting.isLoading ||
          state.cards.isLoading ||
          state.liquidity.isLoading ||
          state.storefront.isLoading ||
          state.pictureBooks.isLoading ||
          state.graphicNovels.isLoading
        );
      },
    }),
    routeName() {
      if (!this.$route.name) {
        return '';
      }
      return this.$route.name.toLowerCase().replace('_', '.');
    },
  },
  methods: {
    setInnerHeight() {
      const app = document.getElementById('app');
      app.style.height = window.innerHeight + 'px';

      const home = document.getElementsByClassName('sp-banner-content');
      if (home.length > 0) {
        const header = document.getElementsByClassName('sp-header');
        home[0].style.height = window.innerHeight - header[0].clientHeight + 'px';
      }
    },
  },
  watch: {
    $route: {
      handler(newValue) {
        // if the url has a #, try to get it into view
        if (newValue.hash) {
          let tries = 0;
          const interval = setInterval(() => {
            const elem = document.getElementById(newValue.hash.replace('#', ''));
            if (elem) elem.scrollIntoView();
            if (elem || tries > 10) clearInterval(interval);
            tries += 1;
          }, 500);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
body {
  overflow: hidden;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
}
.sp-spindle {
  font-family: Quiche, serif;
  font-size: 150%;
}
</style>
<style scoped>
#app-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-padding-top: 54px; /* height of sticky header */
}

#app:not(.sp-custom-bg) {
  background-image: url(./assets/img/banner.png);
}

#app {
  background-repeat: no-repeat;
  background-position: 75% -30px;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

#app.home {
  background-position: 75% 54px;
}

#app-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
  background-color: rgba(63, 63, 63, 0.5);
}
</style>
