import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import('../views/StoriesView.vue'),
  },
  {
    path: '/stories/:storyId/:chapterId',
    name: 'story',
    component: () => import('../views/StoryView.vue'),
  },
  {
    path: '/warp-and-weft',
    name: 'zine',
    component: () => import('../views/ZineView.vue'),
  },
  {
    path: '/graphic-novels',
    alias: ['/publications'],
    name: 'graphic-novels',
    component: () => import('../views/GraphicNovelsView.vue'),
  },
  {
    path: '/graphic-novels/add-listing/:graphicNovelId/:issueId',
    name: 'graphic-novels-add-listing',
    component: () => import('../views/GraphicNovelsView.vue'),
  },
  {
    path: '/graphic-novels/edit/:application',
    name: 'graphic-novels-edit-listing',
    component: () => import('../views/GraphicNovelsView.vue'),
  },
  {
    path: '/graphic-novels/royalties/:application',
    name: 'graphic-novels-royalties-listing',
    component: () => import('../views/GraphicNovelsView.vue'),
  },
  {
    path: '/picture-books',
    name: 'picture-books',
    component: () => import('../views/PictureBooksView.vue'),
  },
  {
    path: '/picture-books/add-listing/:pictureBookId/:issueId',
    name: 'picture-books-add-listing',
    component: () => import('../views/PictureBooksView.vue'),
  },
  {
    path: '/picture-books/edit/:application',
    name: 'picture-books-edit-listing',
    component: () => import('../views/PictureBooksView.vue'),
  },
  {
    path: '/picture-books/royalties/:application',
    name: 'picture-books-royalties-listing',
    component: () => import('../views/PictureBooksView.vue'),
  },
  {
    path: '/whitepaper-roadmap',
    alias: ['/spindle-asa', '/roadmap', '/whitepaper'],
    name: 'roadmap',
    component: () => import('../views/RoadmapView.vue'),
  },
  {
    path: '/token',
    alias: ['/yarn-token'],
    name: 'token',
    component: () => import('../views/TokenView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  },
  {
    path: '/privacy-policy',
    alias: ['/privacy'],
    name: 'privacy',
    component: () => import('../views/PolicyView.vue'),
  },
  {
    path: '/cookie-policy',
    alias: ['/cookies'],
    name: 'cookies',
    component: () => import('../views/PolicyView.vue'),
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import('../views/StaffView.vue'),
  },
  {
    path: '*',
    name: 'Error',
    // lazy-loading
    component: () => import('@/views/ErrorView.vue'),
  },
  {
    path: '/voting-cards',
    name: 'voting-cards',
    component: () => import('../views/VotingCardsView.vue'),
  },
  {
    path: '/store',
    name: 'storefront',
    component: () => import('../views/StorefrontView.vue'),
  },
  {
    path: '/store/add-listing',
    name: 'add-listing',
    component: () => import('../views/StorefrontView.vue'),
  },
  {
    path: '/store/edit/:application',
    name: 'edit-listing',
    component: () => import('../views/StorefrontView.vue'),
  },
  {
    path: '/store/royalties/:application',
    name: 'royalties-listing',
    component: () => import('../views/StorefrontView.vue'),
  },
  {
    path: '/guides/:file?',
    alias: '/guides',
    name: 'guides',
    component: () => import('../views/GuidesView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
